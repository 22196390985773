<template>
  <SweetModal
      ref="showPreview"
      overlay-theme="dark"
      width="80%"
      hide-close-button      
    >
      <iframe
        :srcdoc="previewHtml"
        frameborder="0"
        style="
          width: 100%;
          height: 70vh;
          overflow-y: scroll;
          border: 1px solid lightgray;
        "
      ></iframe>
    </SweetModal>
</template>

<script>
export default {
    props:['previewHtml'],
    methods:{
        open(){
            this.$refs.showPreview.open()
        }
    }
}
</script>

<style>

</style>